import React, { useState, useContext, useEffect } from "react";
import PopUp from "../../component/popUp";
import { toast } from "react-toastify";
import { AddBusinessSector, GetBusinessSector } from "../../API";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import "./style.css";


export default function AddBusinessSectorPopUp({ id }) {
  const { addBusinessSectorPopUp, setAddBusinessSectorPopUp, reload, setReload } = useContext(Context);
  const [addBusinessSectorLoading, setaddBusinessSectorLoading] = useState(false);
  const [businessSectorName, setBusinessSectorName] = useState();
  const handleClose = () => { setAddBusinessSectorPopUp(false); }

  const { t } = useTranslation();

  useEffect(() => {
    if (!id) {
      setBusinessSectorName("");
      return;
    }

    GetBusinessSector({
      "@ID": id
    })
      .then((response) => {
        setBusinessSectorName(response.data.table1[0].BusinessSectorName);

      })
      .catch((error) => {

      });
  }, [id, addBusinessSectorPopUp])


  const save = () => {
    if (!businessSectorName || businessSectorName.trim() === "") {
      toast.error(t("fillAllFields"));
      return;
    }
    setaddBusinessSectorLoading(true);
    AddBusinessSector({
      "@ID": id,
      "@Name": businessSectorName
    })
      .then((response) => {
        setaddBusinessSectorLoading(false);
        toast.success(t("BusinessSectorAddedSuccessfully"));
        setReload(!reload);
        setBusinessSectorName("");
        setAddBusinessSectorPopUp(false);
      })
      .catch((err) => {
        setaddBusinessSectorLoading(false);
        toast.error(`${err}`);
      });
  }
  const popUpTitle = `${t("addBusinessSector")}`;

  const popUpFooter = (
    <>
      <button
        className="footerPopUpBtns"
        disabled={addBusinessSectorLoading}
        onClick={() => save()}
      >
        {addBusinessSectorLoading && (
          <span className="spinner-border text-dark" role="status"></span>
        )}
        {id == null ? t("addBtn") : t("Save")}

      </button>
      <button className="closeBtn" onClick={handleClose}>
        {t("closeBtn")}
      </button>
    </>
  );


  return (

    <PopUp
      popUpTitle={popUpTitle}
      popUpFooter={popUpFooter}
      showPopUp={addBusinessSectorPopUp}
      closePopUp={handleClose}
    >
      <div className="row mb-2">
        <div className="col-12">

          <Field
            inputFieldId={"AddBusinessSector"}
            inputFieldType={"text"}
            labelName={t("BusinessSector")}
            placeholder={t("BusinessSector")}
            inputFieldValue={businessSectorName}
            setOnChange={(value) => setBusinessSectorName(value)}
            isDisabled={addBusinessSectorLoading}
          />
        </div>
      </div>
    </PopUp>
  );
}
