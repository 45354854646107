import React, { useState, createContext } from "react";

export const Context = createContext();

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const firstDay = new Date();
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [reloadCredit, toggleCredit] = useState(false);
  const [userName, setUserName] = useState();
  const [resetPasswordEmail, setResetPasswordEmail] = useState();
  const [historyPopUp, setHistoryPopUp] = useState(false);
  const [updateUserPopUp, setUpdateUserPopUp] = useState(false);
  const [addSenderPopUp, setAddSenderPopUp] = useState(false);
  const [campaignDetailsPopUp, setCampaignDetailsPopUp] = useState(false);
  const [resendPopUp, setResendPopUp] = useState(false);
  const [role, setRole] = useState("");
  const [reload, setReload] = useState(false);
  const [profileLanguage, setProfileLanguage] = useState();
  const [createUserPopUp, setCreateUserPopUp] = useState();
  const [addBusinessSectorPopUp, setAddBusinessSectorPopUp] = useState();
  const [deleteBusinessSectorPopUp, setDeleteBusinessSectorPopUp] = useState();
  const [deletePriceListPopUp, setDeletePriceListPopUp] = useState();
  const [addPriceListPopUp, setAddPriceListPopUp] = useState();
  const [startDate, setStartDate] = useState(new Date(firstDay.getFullYear(), firstDay.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());

  let state = {
    isLoggedIn,
    setIsLoggedIn,
    reloadCredit,
    toggleCredit,
    userName,
    setUserName,
    reload,
    setReload,
    role,
    setRole,
    profileLanguage,
    setProfileLanguage,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    historyPopUp,
    setHistoryPopUp,
    updateUserPopUp,
    setUpdateUserPopUp,
    resetPasswordEmail,
    setResetPasswordEmail,
    campaignDetailsPopUp,
    setCampaignDetailsPopUp,
    resendPopUp,
    setResendPopUp,
    addSenderPopUp,
    setAddSenderPopUp,
    createUserPopUp,
    setCreateUserPopUp,
    addBusinessSectorPopUp,
    setAddBusinessSectorPopUp,
    deleteBusinessSectorPopUp,
    setDeleteBusinessSectorPopUp,
    addPriceListPopUp,
    setAddPriceListPopUp,
    deletePriceListPopUp,
    setDeletePriceListPopUp
  };

  return <Context.Provider value={state}>{props.children}</Context.Provider>;
};
