import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../containers/home";
import Login from "../containers/login";
import SignUp from "../containers/signup";
import Confirm from "../containers/confirm";
import { Context } from "../context";
import RequestChangePassword from "../component/requestPasswordChange";
import OTPCode from "../component/OTPCodeScreen";
import Verification from "../component/RegistrationVerification";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { isLoggedIn } = useContext(Context);
  const { role, setRole } = useContext(Context);

  useEffect(() => {
    setRole(localStorage.getItem("userId"));
  });

  return (
    <Routes>
      <Route
        path="/Login"
        element={
          !isLoggedIn ? (
            <Login />
          ) : (
            <Navigate to={"/Dashboard"} />
          )
        }
      />
      <Route
        path="/SignUp"
        element={!isLoggedIn ? <SignUp /> : <Navigate to="/" />}
      />
      <Route
        path="/*"
        element={isLoggedIn ? <Home /> : <Navigate to="/Login" />}
      />
      <Route path="/confirm" element={<Confirm />} />
      <Route path="/requestChangePassword" element={<RequestChangePassword />} />
      <Route path="/code" element={<OTPCode />} />
      <Route path="/emailVerification" element={<Verification />} />
    </Routes>
  );
};
