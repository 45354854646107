import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context";
import { CheckOTP } from "../../API";
import axios from "axios";
import "./style.css";

export default function OTPCode() {
    const { resetPasswordEmail, setResetPasswordEmail } = useContext(Context);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

    const resetPassword = () => {

        if (!passwordRegex.test(newPassword)) {
            toast.error(t("passwordInvalid"));
            return;
        }

        if ((newPassword != "" && newPassword != null) && newPassword !== confirmPassword) {
            toast.error("Password does not match.");
            return;
        }

        setIsLoading(true);
        CheckOTP({
            "@code": otpCode,
            "@newpassword": newPassword,
        })
            .then((response) => {
                if (response.data.data.Table[0].Column1 == 1) {
                    navigate("/login");
                    setResetPasswordEmail("");
                }
                else
                    toast.error("Invalid OTP");

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }
    const checkEmail = () => {

        axios
            .post(
                "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/SendEmail",
                { Email: resetPasswordEmail, isSMSHub: window.location.href.includes("portal.sms") ? 1 : 0 }
            )
            .then((res) => {
                if (res.status === 200) {
                    toast.success(`OTP Code has been sent to your E-mail`)
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
            });

    }
    return (
        <>
            <div className="row">
                <div className={`col-md-4 col-sm-12 leftCol ${i18n.language}`}>
                    <div>
                        <img id="companyLogo" src={window.location.href.includes("portal.sms") ? "images/epushLogo.png" : "images/logo.png"} alt="main logo" />
                    </div>
                    <div className="forgotPasswordTitle">
                        {t("checkEmail")}
                        <br />
                        {t("yourEmail")}
                    </div>
                    <div className="forgotPasswordDesc">
                        {t("weHaveSent")}<br />{t("informationTo")}
                    </div>
                    <div className="form-group styelFiled1">
                        <Field
                            inputFieldValue={otpCode}
                            setOnChange={setOtpCode}
                            placeholder={t("enterOTP")}
                            labelName={t("oTPVerfication")}
                        />
                    </div>
                    <div className="form-group styelFiled1">
                        <Field
                            inputFieldValue={newPassword}
                            setOnChange={setNewPassword}
                            placeholder={t("newPassword")}
                            labelName={t("newPassword")}
                            inputFieldType={"password"}
                        />
                    </div>
                    <div className="form-group styelFiled">
                        <Field
                            inputFieldValue={confirmPassword}
                            setOnChange={setConfirmPassword}
                            placeholder={t("confirmPassword")}
                            labelName={t("confirmPassword")}
                            inputFieldType={"password"}
                        />
                    </div>

                    <div className="form-group pt-1 mt-3">
                        <button id="requestChangeBtn" disabled={isLoading} onClick={() => resetPassword()} type="submit">
                            {t("reset")}

                            {isLoading && (
                                <span className="spinner-border" role="status"></span>
                            )}
                        </button>
                    </div>
                    <div className="noteStyle">{t("didnotReceive")}<span className="noteLink" onClick={() => checkEmail()}> {t("resend")}</span></div>
                </div>
                <div class="col-md-8 col-sm-12 rightCol">
                    <img id="changePasswordImg" src="images/changePassword.png" alt="main logo" />
                </div>
            </div>

        </>
    );
}
