import { Snackbar } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export default function CopyToClipboardButton(props) {

    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(true)
        navigator.clipboard.writeText(props.text)
    }
    return (
        <>
            <ContentCopyIcon fontSize='small' onClick={handleClick} style={{
                cursor: "pointer",
                color: "red"
            }} />

            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied Successfully"
            />
        </>
    )
}

