import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { ResendSms } from "../../API";
import { toast } from "react-toastify";
import PopUp from "../popUp";
import Table from "../../component/newTable";
import { useNavigate } from 'react-router-dom';
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import "./style.css";
export default function ResendPopUp({
  campaignID,
  smsCount
}) {

  const { resendPopUp, setResendPopUp, reload, setReload, toggleCredit } = useContext(Context);
  const { t } = useTranslation();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);


  const resendSMS = (id) => {
    setIsLoadingBtn(true);
    ResendSms({
      "@campaignID": id
    })
      .then((response) => {
        setReload(!reload);
        toggleCredit((c) => !c);
        toast.success(t("Campaign resend successfully"));
        setIsLoadingBtn(false);
        setResendPopUp(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoadingBtn(false);
      });

  };

  const resendPopUpTitle =
    <div style={{ textAlign: "center" }}>
      <div>{t("resendConfirmTitle")}</div>
    </div>;
  const resendPopUpFooter = (
    <>
      <button
        className="footerPopUpBtns"
        onClick={() => resendSMS(campaignID)}
      >
        {t("Resend")}
        {isLoadingBtn && (
          <span className="spinner-border" role="status"></span>
        )}
      </button>


      <button
        className="closeBtn"
        onClick={() => setResendPopUp(false)}
      >
        {t("closeBtn")}
      </button>
    </>
  );

  return (
    <>
      <PopUp
        popUpTitle={resendPopUpTitle}
        children={
          <div className="resendPopUpBody">
            {t("resendConfirm")}{smsCount}{t("smss")}
          </div>
        }
        popUpFooter={resendPopUpFooter}
        showPopUp={resendPopUp}
        closePopUp={() => setResendPopUp(false)}
      />
    </>
  );
}
