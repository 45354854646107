import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

const EventCalendar = ({ events }) => {
  const [date, setDate] = useState(new Date());

  return (
    <div style={{ height: "100%", border: "none" }}>
      <Calendar
        style={{ height: 500 }}
        onChange={(newDate) => setDate(newDate)}
        value={date}
        tileClassName={({ date }) => {
          const formattedDate = moment(date).format("MM-DD-YYYY");
          return events.includes(formattedDate) ? "highlight" : "";
        }}
        // tileDisabled={({ date }) => {
        //   const formattedDate = moment(date).format("MM-DD-YYYY");
        //   return !events.includes(formattedDate);
        // }}
        selectRange={false}
        minDetail="year"
        showNeighboringMonth={false}
      />

      <style>
        {`
          .react-calendar {
            border: none;
          }

          .react-calendar__tile {
            height: 100%;
           
            font-size: 9px;
            color: #2B3674;
            background-color: white;
          }
          .react-calendar__tile:disabled {
            background-color: white;
          }

          .react-calendar__tile.highlight:hover,
          .react-calendar__tile.highlight:active,
          .react-calendar__tile.highlight:focus
          {
            background-color:  var(--chart-color);;
          }
          
          .highlight {
            border-radius: 50%;
            color: white;
            cursor: pointer;
            background-color:  var(--chart-color);;
          }

      

          .react-calendar__month-view__weekdays {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
          }

          .react-calendar__month-view__weekdays__weekday {
            font-size: 9px;
            color: #2B3674;
          }

           .react-calendar__tile:hover,
           .react-calendar__tile:active,
           .react-calendar__tile:focus {
           border-radius:50%;
          }
     
          .react-calendar__navigation__label,
          .react-calendar__navigation__arrow {
            font-size: 14px;
            color: #2B3674;
            background-color: transparent !important;
          }
        `}
      </style>
    </div>
  );
};

export default EventCalendar;
