import React, { useState } from "react";
import { CreateTransactionWallet } from "../../API";
import PopUp from "../../component/popUp";
import Field from "../../component/field";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./style.css";

export default function Transaction({
  openTransactionPopUp,
  closeTransactionPopUp,
}) {
  var date = new Date();
  const { t, i18n } = useTranslation();
  const [transactionAmount, setTransactionAmount] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const openUrl = (url) => {
    window.open(url, "_blank");
    closeTransactionPopUp();
  };
  const executeTransaction = () => {
    if (transactionAmount == "" || transactionAmount == null) {
      toast.error(`${t("enterTransactionAmount")}`);
      return;
    }
    setIsLoadingBtn(true);
    CreateTransactionWallet({
      "@amount": transactionAmount
    })
      .then((response) => {
        setTransactionAmount("");
        setIsLoadingBtn(false);
        closeTransactionPopUp();
        openUrl(
          `https://pay.advansystelecom.com/TMEPayments/RedirectTo?serviceID=9&serviceGatewayID=16&inquireInfo=${response.data.table1[0].transactionID
          }&merchantRefNo=${response.data.table1[0].transactionID
          }${date.getSeconds()}&mobileNumber=${response.data.table1[0].PhoneNumber || "01"
          }&amount=${transactionAmount}&view=Fawry`
        );
      })
      .catch((error) => {
        toast.error(error);
        setIsLoadingBtn(false);
      });
  };

  const popUpTitle = (
    <div style={{ textAlign: "center" }}>
      <img
        id="transactionImg"
        src="images/transaction.png"
        alt="Transaction amount"
      />
      <div>{t("rechargeYourBalance")}</div>
    </div>
  );

  const popUpFooter = (
    <>
      <button
        className="footerPopUpBtns"
        disabled={isLoadingBtn}
        onClick={executeTransaction}
      >
        {isLoadingBtn && <span className="spinner-border" role="status"></span>}
        {t("rechargeBtn")}
      </button>
      <button
        className="closeBtn"
        disabled={isLoadingBtn}
        onClick={closeTransactionPopUp}
      >
        {t("closeBtn")}
      </button>
    </>
  );

  return (
    <>
      <PopUp
        popUpTitle={popUpTitle}
        popUpFooter={popUpFooter}
        showPopUp={openTransactionPopUp}
        closePopUp={closeTransactionPopUp}
      >
        <div className={`form-group ${i18n.language}`}>
          <Field
            inputFieldType={"number"}
            inputFieldValue={transactionAmount}
            setOnChange={setTransactionAmount}
            placeholder={t("enterTransactionAmount")}
          />
        </div>
      </PopUp>
    </>
  );
}
