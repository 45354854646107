import React, { useContext, useEffect, useState } from "react";
import { GetCredit, GetUser, GetUserBalance, GetCreditV2 } from "../../API";
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import CurrencyFormat from 'react-currency-format';
import "./style.css";
import Transaction from "../transaction";
import SmsBalance from "../smsBalance";

export default function Header(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [credit, setCredit] = useState();
  const [smsBalance, setSmsBalance] = useState();
  const { setIsLoggedIn, reloadCredit } = useContext(Context);
  const [profileUserName, setProfileUserName] = useState();
  const [profileEmail, setProfileEmail] = useState("No E-mail to show");
  const { reload } = useContext(Context);
  const { profileLanguage, setProfileLanguage } = useContext(Context);
  const [show, setShow] = useState(false);
  const [showSmsPackages, setShowSmsPackages] = useState(false);
  const handleShow = () => setShow(true);
  const handleShowSmsPackages = () => {
    setShowSmsPackages(true);
  };

  const logout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
  };

  const changeLanguage = (language) => {
    if (language === "ar") {
      setProfileLanguage("العربيه");
      i18n.changeLanguage("en");
    } else {
      setProfileLanguage("English");
      i18n.changeLanguage("ar");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("i18nextLng") === "ar")
      setProfileLanguage("English");
    else setProfileLanguage("العربية");

    GetUser({ "@adminID": localStorage.getItem("userId") }).then((data) => {
      setProfileUserName(data.data.table1[0].Username);
      setProfileEmail(data.data.table1[0].Email);
    });

  }, [reload]);



  useEffect(() => {
    // GetCredit().then((data) => {
    //   setCredit(data.data.table1[0].Credit);

    // });
    GetCreditV2().then((data) => {
      if (data.data.table1[0].Credit == null)
        setCredit(0);
      else
        setCredit(data.data.table1[0].Credit);

    });

    GetUserBalance().then((data) => {
      if (data.data.table1[0].Column1 == null)
        setSmsBalance(0);
      else
        setSmsBalance(data.data.table1[0].Column1);
    });
  }, [reloadCredit, reload]);

  return (
    <>
      <nav>
        <div className="profile-details">
          <span className="mainPageTitleHeader">{props.title}</span>
          <div className="balanceAndCreditDiv">

            <div className={`userBalance ${i18n.language}`} onClick={!window.location.href.includes("portal.sms") && handleShowSmsPackages}>
              <i className="headerCardIcon">
                <img
                  id="balanceImg"
                  src="images/smsBalance.png"
                  alt="sms balance"
                />
              </i>
              <span className="headerCardNote">{t("BusinessSMSPackages")}</span>
              <span>
                <CurrencyFormat className="headerCardSmsContent" value={smsBalance} displayType={'text'} thousandSeparator={true} />
              </span>
            </div>

            <div className={`admin_amount ${i18n.language}`} onClick={!window.location.href.includes("portal.sms") && handleShow}>
              <i className="headerCardIcon">
                <img
                  id="amountImg"
                  src="images/money.png"
                  alt="amount of money"
                />
              </i>
              <span className="headerCardNote">{t("balance")}</span>
              <span className="headerCardMoneyContent" >
                <CurrencyFormat value={credit} displayType={'text'} thousandSeparator={true} />
                {t("currency")}
              </span>
            </div>
            <Dropdown className="admin_amount">

              <Dropdown.Toggle
                style={{ backgroundColor: "transparent" }}
                className={`profileDropDownListStyle ${i18n.language}`}
              >
                <img
                  id="balanceImg"
                  src="images/accounts.png"
                  alt="accounts"
                />
                <span style={{ color: "black", marginLeft: "5px" }}>
                  {localStorage.getItem("userName")}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className={`profileMenuStyle ${i18n.language}`}>
                <Dropdown.Header className="admin_name">
                  {profileUserName}
                  <br />
                  {/* <span className="admin_email">{profileEmail}</span> */}
                </Dropdown.Header>
                {/* <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item
                className="dropDownItem"
                onClick={() => navigate("/updateAccount")}
              >
                <i className={`far fa-user profileIcon ${i18n.language}`}></i>
                <span className="myProfileListStyle">{t("myProfile")}</span>
              </Dropdown.Item> */}
                <Dropdown.Divider></Dropdown.Divider>
                <Dropdown.Item
                  className="dropDownItem"
                  onClick={() => changeLanguage(i18n.language)}
                >
                  {" "}
                  <i className={`fas fa-globe profileIcon ${i18n.language}`}></i>
                  <span className="myProfileListStyle">{profileLanguage}</span>
                </Dropdown.Item>
                <Dropdown.Divider></Dropdown.Divider>
                <Dropdown.Item
                  className="dropDownItem"
                  onClick={logout}
                >
                  {" "}
                  <i
                    className={`fa-solid fa-arrow-right-from-bracket logOutIcon ${i18n.language}`}
                  ></i>
                  <span className="myProfileListStyle">{t("logOut")}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>


        </div>
      </nav>

      <Transaction
        openTransactionPopUp={show}
        closeTransactionPopUp={() => setShow(false)}
      />
      <SmsBalance
        openSmsBalancePopUp={showSmsPackages}
        closeSmsBalancePopUp={() => setShowSmsPackages(false)}
      />
    </>
  );
}
