import React, { useState, useMemo, useCallback, useContext, useEffect } from "react";
import { GetAdminAPIReport, CreateAPIReportRequest, GetSenders } from "../../API";
import { toast } from "react-toastify";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import { useParams } from "react-router-dom";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import Select from "react-select";
import "./style.css";

export default function APITableReport() {
  const { t } = useTranslation();
  const { reload, setReload, startDate, endDate } = useContext(Context);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [filterSender, setFilterSender] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState({});
  const [senderList, setSenderList] = useState([]);
  const [selectedSender, setSelectedSender] = useState({ value: "", label: t("allSender") });
  // const [senderList, setSenderList] = useState([]);
  // const [selectedSender, setSelectedSender] = useState("-1");

  const [toggleSideBar] = useState(false);

  useEffect(() => {
    GetSenders({}).then((response) => {
      setSenderList(response.data.table1);
    });

  }, [t]);

  const requestHandler = () => {
    setIsLoadingBtn(true);
    CreateAPIReportRequest({
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      "@senderFilter": selectedSender.value
    })
      .then((response) => {
        setIsLoadingBtn(false);
        if (response.data.table1[0].success === 1) {
          toast.success(t("apiRequestSuccess"));
          setReload(!reload);
        } else if (response.data.table1[0].success === -1) {
          toast.error(t("noAPIReport"));
        }
        else
          toast.error(t("apiRequestError"));
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };



  const downloadApiReport = (name, path) => {
    setDownloadLoading(prevState => ({ ...prevState, [path]: true }));
    window.open(`https://hub.advansystelecom.com/generalapiv12/api/downloadTemplate?templateName=${name}&filePath=${path}`, "_self")
    setDownloadLoading(prevState => ({ ...prevState, [path]: false }));

  };
  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("sender")}</div>
            <input
              type="text"
              className="searchFilter"
              value={filterSender}
              onChange={(e) => setFilterSender(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "sender",
      },
      {
        Header: t("startDateReport"),
        accessor: "startDate",
      },
      {
        Header: t("endDateReport"),
        accessor: "endDate",
      },
      {
        Header: t("report"),
        accessor: "filePath",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <button className="apiReportBtn" disabled={original.filePath == null || downloadLoading[original.filePath]} onClick={() => downloadApiReport(original.fileName, '\\\\192.186.100.24\\Storage\\APIIntegrationReports\\')}>
                {original.filePath == null ? (
                  <div >

                    {t("reportInProgress")}
                  </div>
                ) : (
                  <div>
                    {downloadLoading[original.filePath] && (
                      <span className="spinner-border" role="status"></span>
                    )}
                    <i className="fas fa-file-download"></i>
                    {t("downloadReport")}
                  </div>
                )}
              </button>
            </div>
          );
        },
      },
    ],
    [t, downloadLoading]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetAdminAPIReport({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@senderFilter": filterSender,
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [t, reload, filterSender]
  );

  return (
    <div className="dlrContainer">
      <Header
        title={t("APIReport")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <DateRangePicker />
        <div style={{ width: "192px" }}>
          <Select
            id="senderDL"
            className="form-select"
            value={selectedSender}
            onChange={setSelectedSender}
            options={[
              { value: "", label: t("allSender") },
              ...senderList.map((s) => ({ value: s.Name, label: s.Name })),
            ]}
            placeholder={t("selectSender")}
          />

        </div>

        <button
          className="requestAPIReportBtn"
          onClick={() => requestHandler()}
          disabled={isLoadingBtn}
        >
          {t("RequestAPIReport")}
          {isLoadingBtn && (
            <span className="spinner-border" role="status"></span>
          )}
        </button>
      </div>

      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
        />
      </div>
    </div>
  );
}

