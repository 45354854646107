import React, { useState } from "react";
import { toast } from "react-toastify";
import Field from "../field";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckEmailOTP } from "../../API";
import "./style.css";

export default function Verification() {
    const [otpCode, setOtpCode] = useState("");
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const verifyEmail = () => {
        setIsLoading(true);
        CheckEmailOTP({
            "@code": otpCode
        })
            .then((response) => {
                if (response.data.data.Table[0].Column1 == 1) {
                    navigate("/login");

                }
                else
                    toast.error("Invalid OTP");

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }
    return (
        <>
            <div className="row">
                <div className={`col-md-4 col-sm-12 leftCol ${i18n.language}`}>
                    <div>
                        <img id="companyLogo" src={window.location.href.includes("portal.sms") ? "images/epushLogo.png" : "images/logo.png"} alt="main logo" />
                    </div>
                    <div className="registerationVerfiyTitle">
                        {t("checkEmail")}
                        <br />
                        {t("yourEmail")}
                    </div>
                    <div className="registerationVerfiyDesc">
                        {t("verifyEmailTitle")}
                    </div>
                    <div className="form-group styelFiled1">
                        <Field
                            inputFieldValue={otpCode}
                            setOnChange={setOtpCode}
                            placeholder={t("enterOTP")}
                            labelName={t("oTPVerfication")}
                        />
                    </div>
                    <div className="form-group pt-1 mt-3">
                        <button id="verfiyBtn" disabled={isLoading} onClick={() => verifyEmail()} type="submit">
                            {t("verify")}
                            {isLoading && (
                                <span className="spinner-border" role="status"></span>
                            )}
                        </button>
                    </div>
                </div>
                <div class="col-md-8 col-sm-12 rightCol">
                    <img id="changePasswordImg" src="images/changePassword.png" alt="main logo" />
                </div>
            </div>
        </>
    );
}
