import React, {
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import PopUp from "../../component/popUp";
import Table from "../../component/newTable";
import { toast } from "react-toastify";
import {
  GetClients,
  AddSender,
  UploadSenderFile
} from "../../API";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import i18next from "i18next";
import Select from "react-select";
import "./style.css";

export default function AddSenderPopUp() {
  const { addSenderPopUp, setAddSenderPopUp } = useContext(Context);
  const [senderName, setSenderName] = useState();
  const [clientsList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(-1);
  const handleClose = () => setAddSenderPopUp(false);
  const [taxCardFileName, settaxCardFileName] = useState("");
  const [commercialFileName, setCommercialFileName] = useState("");
  const [delegationLetterFileName, setdelegationLetterFileName] = useState("");
  const [addSenderLoading, setAddSenderLoading] = useState(false);
  const taxFileRef = useRef();
  const commercialFileRef = useRef();
  const delegationLetterFileRef = useRef();
  const handleTaxCardFile = () => {
    let taxFile = taxFileRef.current.files[0];
    if (taxFile) settaxCardFileName(taxFile.name);
  };
  const handleCommercialRegisterFile = () => {
    let commercialFile = commercialFileRef.current.files[0];
    if (commercialFile) setCommercialFileName(commercialFile.name);
  };
  const handleDelegationLetterFile = () => {
    let delegationLetterFile = delegationLetterFileRef.current.files[0];
    if (delegationLetterFile)
      setdelegationLetterFileName(delegationLetterFile.name);
  };

  const { t } = useTranslation();


  const popUpTitle = `${t("uploadRequiredFiles")}`;

  const popUpFooter = (
    <>
      <button
        className="footerPopUpBtns"
        disabled={addSenderLoading}
        onClick={() => save()}
      >
        {addSenderLoading && (
          <span className="spinner-border text-dark" role="status"></span>
        )}
        {t("addBtn")}
      </button>
      <button className="closeBtn" onClick={handleClose}>
        {t("closeBtn")}
      </button>
    </>
  );

  const save = () => {
    let taxCardFile = taxFileRef.current.files[0];
    let commercialFile = commercialFileRef.current.files[0];
    let delegationLetterFile = delegationLetterFileRef.current.files[0];

    if (
      !commercialFile ||
      !taxCardFile ||
      !delegationLetterFile ||
      senderName === "" ||
      selectedClient == -1
    ) {
      toast.error(`${t("addSenderError")}`);
      return;
    }

    setAddSenderLoading(true);
    let formData = new FormData();

    formData.append("commercialFile", commercialFile);
    formData.append("taxCardFile", taxCardFile);
    formData.append("delegationLetterFile", delegationLetterFile);
    return UploadSenderFile(formData)
      .then((res) => {
        if (res.status === 200) {
          taxFileRef.current.value = null;
          commercialFileRef.current.value = null;
          delegationLetterFileRef.value = null;

          AddSender({
            "@sender": senderName,
            "@commercialRegister": res.data[0].Column1,
            "@taxCard": res.data[1].Column1,
            "@delegationLetter": res.data[2].Column1,
            "@clientID": selectedClient.value,
          }).then((response) => {
            toast.success(`${t("senderAddedSuccessfully")}`);
            setAddSenderPopUp(false);
            setAddSenderLoading(false);
            setCommercialFileName("");
            settaxCardFileName("");
            setSenderName("");
            setSelectedClient.value(-1);
          });
        }
      })
      .catch((error) => { });
  };


  useEffect(() => {
    GetClients().then((response) => {
      setClientsList(response.data.table1);
    });
  }, [t]);

  return (

    <PopUp
      popUpTitle={popUpTitle}
      popUpFooter={popUpFooter}
      showPopUp={addSenderPopUp}
      closePopUp={handleClose}
    >
      <div>
        <div className="filesUploadDiv">
          <div className="formCard">
            <div className="uploadFileIcon">
              <img
                className="uploadCloud"
                src="images/upload.png"
                alt="upload icon"
              />
              <div className="uploadInfo">
                {t("selectAndUpload")}
                <span style={{ color: " var(--second-color);" }}>
                  {t("taxCardFileName")}
                </span>
              </div>
              <div className="uploadFileType">
                {t("supportedFormates")}
                <span id="fileName">{"( " + taxCardFileName + ") "}</span>
              </div>
            </div>

            <input
              className="inputFilesUpload"
              ref={taxFileRef}
              id="uploadedFile"
              type="file"
              onChange={handleTaxCardFile}
              accept=".pdf"
            />
          </div>
          {taxCardFileName && <div className="uploadProgress mt-2"></div>}
        </div>
        <div className="filesUploadDiv mt-3">
          <div className="formCard">
            <div className="uploadFileIcon">
              <img
                className="uploadCloud"
                src="images/upload.png"
                alt="upload icon"
              />
              <div className="uploadInfo">
                {t("selectAndUpload")}
                <span style={{ color: " var(--second-color);" }}>
                  {t("commercialRegisterFileName")}
                </span>
              </div>
              <div className="uploadFileType">
                {t("supportedFormates")}
                <span id="fileName">
                  {"( " + commercialFileName + " )"}
                </span>
              </div>
            </div>
            <input
              className="inputFilesUpload"
              ref={commercialFileRef}
              id="uploadedFile"
              type="file"
              onChange={handleCommercialRegisterFile}
              accept=".pdf"
            />
          </div>
          {commercialFileName && (
            <div className="uploadProgress mt-3"></div>
          )}
        </div>

        <div className="filesUploadDiv mt-3">
          <div className="formCard">
            <div className="uploadFileIcon">
              <img
                className="uploadCloud"
                src="images/upload.png"
                alt="upload icon"
              />
              <div className="uploadInfo">
                {t("selectAndUpload")}
                <span style={{ color: " var(--second-color);" }}>
                  {t("Delegation Letter")}
                </span>
              </div>
              <div className="uploadFileType">
                {t("supportedFormates")}
                <span id="fileName">
                  {"( " + delegationLetterFileName + " )"}
                </span>
              </div>
            </div>
            <input
              className="inputFilesUpload"
              ref={delegationLetterFileRef}
              id="uploadedFile"
              type="file"
              onChange={handleDelegationLetterFile}
              accept=".pdf"
            />
          </div>
          {delegationLetterFileName && (
            <div className="uploadProgress mt-3"></div>
          )}
        </div>

        <div></div>
        <div
          className={`form-group mt-3 enterDataInput ${i18next.language}`}
        >
          <Field
            inputFieldValue={senderName}
            setOnChange={setSenderName}
            placeholder={t("enterSender")}
            max={11}
          />
          {/* <select
                className="form-select clientDLStyle"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                <option value="-1" disabled={true}>
                  {t("selectClient")}
                </option>

                {clientsList &&
                  clientsList.map((s, i) => (
                    <option value={s.ID} key={i}>
                      {s.Name}
                    </option>
                  ))}
              </select> */}

          <Select
            id="clientDL"
            className="form-select"
            value={selectedClient}
            onChange={setSelectedClient}
            options={clientsList.map((c) => ({
              value: c.ID,
              label: c.Name,
            }))}
          />
        </div>
      </div>
    </PopUp>
  );
}
