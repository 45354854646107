import React, { useState, useMemo, useCallback, useContext } from "react";
import { GetCreditReport, GetUserCreditDetails } from "../../API";
import { Context } from "../../context";
import { toast } from "react-toastify";
import TableDatePicker from "../../component/datePicker";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import "./style.css";

export default function Transaction() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState();
  const [pageCount, setPageCount] = useState();
  const { startDate, endDate, role } =
    useContext(Context);
  const [data, setData] = useState([]);
  const [toggleSideBar] = useState(false);
  const { t } = useTranslation();

  const exportHandler = () => {
    setIsLoadingBtn(true);
    GetUserCreditDetails({
      "@username": selectedUserName,
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
    })
      .then((response) => {
        setIsLoadingBtn(false);
        let link = document.createElement("a");

        link.download = "Report";
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          response.data;

        if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent))
          link.download += ".xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("date"),
        accessor: "Date",
      },
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("userName")}</div>
            {(localStorage.getItem("admin") === "1" || localStorage.getItem("admin") === "2") && (
              <input
                type="text"
                className="searchFilter"
                value={selectedUserName}
                onChange={(e) => setSelectedUserName(e.target.value)}
                placeholder="Search"
              />
            )}
          </div>
        ),
        accessor: "UserName",
      },
      {
        Header: t("credit") + " (EGP)",
        accessor: "Amount",
        Cell: ({ row: { original } }) => {
          return (
            <CurrencyFormat
              value={original.Amount}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        },
      },
      {
        Header: t("method"),
        accessor: "AdminName",
        Cell: ({ row: { original } }) => {
          if (original.Admin === null) return "Online Payment";
          else return t(original.Admin);
        },
      },
      {
        Header: t("paymentMethodWay"),
        accessor: "PaymentMethod",
      }
    ],
    [t, role]
  );

  const columnsUser = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("date"),
        accessor: "Date",
      },
      {
        Header: t("credit") + " (EGP)",
        accessor: "Amount",
        Cell: ({ row: { original } }) => {
          return (
            <CurrencyFormat
              value={original.Amount}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        },
      },
      {
        Header: t("method"),
        accessor: "AdminName",
        Cell: ({ row: { original } }) => {
          if (original.Admin === null) return "Online Payment";
          else return t(original.Admin);
        },
      },
      {
        Header: t("PaymentMethod"),
        accessor: "PaymentMethod",
      }
    ],
    [t, role]
  );
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetCreditReport({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
        "@username": selectedUserName,
      })
        .then((response) => {
          setIsLoading(false);

          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [t, startDate, endDate, selectedUserName, role]
  );

  return (
    <div className="transactionContainer">
      <Header
        title={t("transactionTitle")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <DateRangePicker />

        <button
          className="exportBtn"
          onClick={() => exportHandler()}
          disabled={isLoadingBtn}
        >
          {t("exportReport")}
          {isLoadingBtn && (
            <span className="spinner-border" role="status"></span>
          )}
        </button>
      </div>
      <div className="tableDiv">
        <Table
          columns={(localStorage.getItem("admin") === "1" || localStorage.getItem("admin") === "2") ? columns : columnsUser}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("transactionTitle")}
        />
      </div>
    </div>
  );
}
