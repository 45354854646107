import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import Field from "../../component/field";
import Select from "react-select";
import "./style.css";
import axios from "axios";
import CopyToClipboardButton from "./CopyToClipboardButton";
import Header from "../../component/header";
import {
  GetattemptsCount,
  UpdateattemptsCount,
  GetClientsSenders,
  GetClientOperators,
} from "../../API";

export default function Reports() {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const { reload, setReload } = useContext(Context);
  const [message, setMessage] = useState("");
  const [requestID, setRequestID] = useState("");
  const [senderList, setSenderList] = useState([]);
  const [selectedSender, setSelectedSender] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [selectedOperatorID, setSelectedOperatorID] = useState(null);
  const [attemptsCount, setAttemptsCount] = useState(0);
  const [toggleSideBar] = useState(false);
  // const GenerateApiKeyURL = "http://localhost:2735/api/bulkSMS/GenerateApiKey";
  // const SMSIntegrate = "http://localhost:2735/api/bulkSMS/ForwardSMS";

  const GenerateApiKeyURL =
    "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/GenerateApiKey";
  const SMSIntegrate =
    "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/ForwardSMS";
  const Integration =
    "https://hub.advansystelecom.com/generalapiv12/BulkSMS/Integration.pdf";
  const [ApiKey, setApiKey] = useState(null);

  useEffect(() => {
    GetattemptsCount().then((res) => {
      setAttemptsCount(res.data.table1[0].TestLimit);
      setApiKey(res.data.table1[0].IntegrationKey)
    });
  }, []);

  useEffect(() => {
    GetClientsSenders({
      "@ClientID": localStorage.getItem("userId"),
    })
      .then((response) => {
        setSenderList(response.data.table1);
      })
      .catch((error) => {
        console.error(error);
      });

    GetClientOperators({
      "@adminID": localStorage.getItem("userId"),
    }).then((response) => {
      setOperatorList(response.data.table1);
    });
  }, [t]);

  const downloadDoc = () => {
    window.open(Integration, "_blank");
  };

  const GenerateApiKey = () => {
    axios
      .post(
        GenerateApiKeyURL,
        {
          ConnectionStringName: "AdvansysBulk",
          StoredProcedureName: "Dashboard.UpdateApiIntergration",
          SpParams: {},
        },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((ress) => {
        setApiKey(ress.data);
        console.log(ress);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendTestSMSIntegrate = () => {
    if (selectedSender === null) {
      toast.error(t("errorSender"));
      return;
    }
    if (selectedOperatorID === null) {
      toast.error(t("errorOperator"));
      return;
    }
    // if (attemptsCount === 0) {
    //   toast.error("your attempts are over");
    //   return;
    // }
    // console.log(ApiKey);
    axios
      .post(
        SMSIntegrate,
        {
          PhoneNumber: phoneNumber,
          Message: message,
          SenderName: selectedSender.label,
          RequestID: requestID,
          OperatorID: selectedOperatorID.value,
        },
        {
          headers: {
            Authorization: ApiKey,
            Testing: 1,
          },
        }
      )
      .then((ress) => {
        if (ress.status === 200) {
          setAttemptsCount(attemptsCount - 1);
          toast.success("Success");
          setReload(!reload);
        } else {
          toast.error(ress.data);
          setReload(!reload);
        }
      })
      .catch((res) => {
        if (res.response.data.Message.includes("Exception")) {
          toast.error("something went wrong , Try again");
          return;
        }
        toast.error(res.response.data.Message);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "200px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected ? "blue" : "white",
      fontSize: "14px",
    }),
  };

  return (
    <div className="apiContainer">
      <Header
        title={t("apiIntegration")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />

      <div className="apiIntegrationMainDiv">
        <div className="helpBtnDiv">
          <button className="apiHelpBtnsStyle" onClick={() => downloadDoc()}>
            <i className="fas fa-info-circle"></i>
            {t("help")}
          </button>
        </div>

        <div className="apiIntegrationTitle">
          {t("apiIntegration")} :{" "}
          <span className="apilink">
            https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/ForwardSMS{" "}
          </span>
        </div>
        <div className="apiIntegrationCodeArea">
          {"{"}
          <br />
          <div className="integrationData">
            <div className="title">"{t("SenderName")}":</div>
            {/* <div>
              {SenderName}
            </div> */}

            <Select
              className="form-select"
              value={selectedSender}
              onChange={setSelectedSender}
              styles={customStyles}
              options={senderList.map((s) => ({
                value: s.ID,
                label: s.Name,
              }))}
              placeholder={t("selectSender")}
            />
          </div>
          <div className="integrationData">
            <div className="title">"{t("OperatorID")}":</div>
            <Select
              className="form-select"
              value={selectedOperatorID}
              onChange={setSelectedOperatorID}
              styles={customStyles}
              options={operatorList.map((o) => ({
                value: o.ID,
                label: o.Name == "Mobinil" ? "Orange" : o.Name,
              }))}
              placeholder={t("selectOperator")}
            />
          </div>
          <div className="integrationData">
            <div className="title">"{t("RequestID")}":</div>
            <Field
              inputFieldValue={requestID}
              setOnChange={setRequestID}
              inputFieldType="number"
            />
          </div>

          <div className="integrationData">
            <div className="title">"{t("PhoneNumber")}":</div>
            <Field
              inputFieldValue={phoneNumber}
              setOnChange={setPhoneNumber}
              inputFieldType="number"
            />
          </div>
          <div className="integrationData">
            <div className="title">"{t("Message")}":</div>
            <Field inputFieldValue={message} setOnChange={setMessage} />
          </div>

          {"}"}
        </div>
        <div className="apiIntegrationBtns">
          <button className="apiBtnsStyle" onClick={() => GenerateApiKey()}>
            {t("Generate Key")}
          </button>
          {ApiKey != null && (
            <button
              className="apiTestBtnsStyle"
              onClick={() => sendTestSMSIntegrate()}
            >
              {t("Test")}
            </button>
          )}
          {/* <div className="attemptsDiv"> {attemptsCount} Attempts Left</div> */}
        </div>
        {ApiKey != null && (
          <div className="integrationKey">
            {"API-Key : " + ApiKey + "  "}

            <CopyToClipboardButton text={ApiKey} />
          </div>
        )}
      </div>
    </div>
  );
}
