import axios from "axios";
import { toast } from "react-toastify";
const API_URL = "https://hub.advansystelecom.com/generalapiv12/api/general";
const Excel_API =
  "https://hub.advansystelecom.com/generalapiv12/api/excel/export";
const AUTH =
  "https://hub.advansystelecom.com/generalapiv12/api/dashboard/token";
const TestSMS_API =
  "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/SendSMS";
const UPLOAD_EXCEL_FILE =
  "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/UploadExcel";
const UPLOAD_PhoneNumbers =
  "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/AddPhoneNumbersList";
const UPLOAD_FILES =
  "https://hub.advansystelecom.com/generalapiv12/api/upload/files";
export const BulkSMSForm_SAMPLE =
  "https://hub.advansystelecom.com/generalapiv12/api/downloadTemplate?templateName=BulkSMS/bulkTemplate.xlsx";
export const OneToOneSMSForm_SAMPLE =
  "https://hub.advansystelecom.com/generalapiv12/api/downloadTemplate?templateName=BulkSMS/oneToOneTemplate.xlsx"
export const Delegation_Letter_Sample =
  "https://hub.advansystelecom.com/generalapiv12/api/downloadTemplate?templateName=BulkSMS/DelegationLetter.docx"

const CreateRequest = (procName, params, API, tablesNames, isFormData) => {

  return new Promise((resolve, reject) => {

    axios.post(
      API || API_URL,
      isFormData ? params : {
        ConnectionStringName: "AdvansysBulk",
        StoredProcedureName: procName,
        SpParams: params,
        TablesNames: tablesNames
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then(response => resolve(response))
      .catch(error => {
        console.log(error)
        if (error.response?.data["error-message"] == "30" || error.response?.data["error-message"] == "20") {
          localStorage.clear()
          window.location.reload()
        }
        toast.error(error.response?.data?.Message || error)
        reject(error)
      })
  })
};

export const GetattemptsCount = () => {
  return CreateRequest("[Dashboard].GetattemptsCount", {
    "@userID": localStorage.getItem("userId"),
  });
};


export const Login = (params) => {
  return CreateRequest(
    "Dashboard.Login",
    {
      ...params,
    },
    AUTH
  );
};

export const UploadFile = (formData) => {
  return CreateRequest(null,

    formData,

    UPLOAD_EXCEL_FILE, null, true
  );
};

export const UploadPhoneNumbersList = (formData) => {
  return CreateRequest(null,

    formData,

    UPLOAD_PhoneNumbers, null, true
  );
};


export const UploadSenderFile = (formData) => {
  return CreateRequest(null,

    formData,

    UPLOAD_FILES, null, true
  );
};

export const TestMessage = (params) => {
  return CreateRequest(
    "Dashboard.TestBulkSMS",
    {
      ...params,
      "@adminID": localStorage.getItem("userId"),
    },
    TestSMS_API
  );
};

export const SignUp = (params) => {
  return CreateRequest(
    "Unauthorized.Register",
    {
      ...params,
    },
    AUTH
  );
};
export const GetSenders = () => {
  return CreateRequest("Dashboard.GetUserSenders", {
    "@adminID": localStorage.getItem("userId"),
  });
};
export const AddSender = (params) => {
  return CreateRequest("[Dashboard].AddSender", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const AddContactsGroup = (params) => {
  return CreateRequest("[Dashboard].AddContactsGroup", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetContactsGroup = (params) => {
  return CreateRequest("[Dashboard].GetContactsGroup", {
    "@adminID": localStorage.getItem("userId"),
  });
};

export const DeleteContactsGroup = (params) => {
  return CreateRequest("[Dashboard].DeleteContactsGroup", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetUserGroups = () => {
  return CreateRequest("[Dashboard].GetUserGroups", {
    "@adminID": localStorage.getItem("userId"),
  });
};
export const GetUserGroupsData = (params) => {
  return CreateRequest("[Dashboard].GetUserGroupsData", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const EditContactsGroup = (params) => {
  return CreateRequest("[Dashboard].EditContactsGroup", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const UpdateUser = (params) => {
  return CreateRequest("[Dashboard].UpdateUser", {
    ...params,
    "@adminID": Number(localStorage.getItem("userId")),
  });
};

export const GetUser = (params) => {
  return CreateRequest("[Dashboard].GetUser", {
    ...params,
  });
};

export const GetUserPackages = () => {
  return CreateRequest("[Dashboard].GetUserPackages", {
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAllSenders = (params) => {
  return CreateRequest("Dashboard.GetAllSenders", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetClientOperators = (params) => {
  return CreateRequest("Dashboard.GetClientOperators", {
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAccountSenders = (params) => {
  return CreateRequest("Dashboard.GetAccountSenders", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const AddBusinessSector = (params) => {
  return CreateRequest("Dashboard.AddBusinessSector", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};


export const DeleteBusinessSector = (params) => {
  return CreateRequest("Dashboard.DeleteBusinessSector", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetBusinessSector = (params) => {
  return CreateRequest("Dashboard.GetBusinessSector", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const AddPriceList = (params) => {
  return CreateRequest("Dashboard.AddPriceList", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const DeletePriceList = (params) => {
  return CreateRequest("Dashboard.DeletePriceList", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetPriceList = (params) => {
  return CreateRequest("Dashboard.GetPriceList", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const CreateTransaction = (params) => {
  return CreateRequest("Dashboard.CreateTransactionV2", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const CreateTransactionWallet = (params) => {
  return CreateRequest("Dashboard.CreateTransaction", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const CreateCustomPack = (params) => {
  return CreateRequest("Dashboard.CreateCustomPack", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetReports = (params) => {
  return CreateRequest("[Dashboard].GetUserTotalsReport", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAdminAPIReport = (params) => {
  return CreateRequest("[Dashboard].GetAdminAPIReport", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const ChangeSenderOperatorActivation = (params) => {
  return CreateRequest("[Dashboard].ChangeSenderOperatorActivation", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAllUserPackages = (params) => {
  return CreateRequest("[Dashboard].GetAllUserPackages", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAllUserPackagesExport = (params) => {
  return CreateRequest("[Dashboard].GetAllUserPackagesExport", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  }, Excel_API);
};


export const CreateAPIReportRequest = (params) => {
  return CreateRequest("[Dashboard].CreateAPIReportRequest", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};


export const GetScheduleSMSReport = (params) => {
  return CreateRequest("[Dashboard].GetScheduleSMSReport", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const DeleteScheduleSMS = (params) => {
  return CreateRequest("[Dashboard].DeleteScheduleSMS", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const EditScheduleSMS = (params) => {
  return CreateRequest("[Dashboard].EditScheduleSMS", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAdminReport = (params) => {
  return CreateRequest("[Dashboard].GetAdminReport", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};


export const GetUserStatistics = (params) => {
  return CreateRequest("[Dashboard].GetUserStatistics", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetStatisticsAdmin = (params) => {
  return CreateRequest("[Dashboard].GetStatisticsAdmin", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};


export const GetChartData = (params) => {
  return CreateRequest("[Dashboard].GetChartData", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAdminChartData = (params) => {
  return CreateRequest("[Dashboard].GetAdminChartData", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};
export const GetSenderFilterList = (params) => {
  return CreateRequest("[Dashboard].[GetSenderFilterList]", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};
export const GetClientFilterList = () => {
  return CreateRequest("[Dashboard].[GetClientFilterList]", {
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAccounts = (params) => {
  return CreateRequest("[Dashboard].GetAllClients", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAccountsDetails = (params) => {
  return CreateRequest("[Dashboard].GetAllClientsDetails", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  },
    Excel_API);
};

export const GetClientOperator = (params) => {
  return CreateRequest("[Dashboard].[GetClientOperator]", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const AddAmountByAdmin = (params) => {
  return CreateRequest("[Dashboard].AddAmountByAdmin", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetCredit = () => {
  return CreateRequest("[Dashboard].[GetUserCredit]", {
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetCreditV2 = () => {
  return CreateRequest("[Dashboard].[GetUserCreditV2]", {
    "@adminID": localStorage.getItem("userId"),
  });
};


export const GetUserBalance = () => {
  return CreateRequest("[Dashboard].[GetUserBalance]", {
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetCreditReport = (params) => {
  return CreateRequest("[Dashboard].[GetUserCreditReport]", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};
export const GetPackages = () => {
  return CreateRequest("Unauthorized.GetPackages", {
  },
    AUTH
  );
};
export const GenerateOTP = (params) => {
  return CreateRequest("Unauthorized.GenerateOTP", {
    ...params
  },
    AUTH
  );
};
export const CheckOTP = (params) => {
  return CreateRequest("Unauthorized.CheckOTP", {
    ...params
  },
    AUTH
  );
};
export const CheckEmailOTP = (params) => {
  return CreateRequest("Unauthorized.CheckEmailOTP", {
    ...params
  },
    AUTH
  );
};
export const ForgetPassword = (params) => {
  return CreateRequest("Unauthorized.ResetUserPassword", {
    ...params
  },
    AUTH
  );
};
export const GetUserCreditDetails = (params) => {
  return CreateRequest(
    "[Dashboard].[GetUserCreditDetails]",
    { ...params, "@adminID": localStorage.getItem("userId") },
    Excel_API
  );
};
export const GetUserCreditHistory = (params) => {
  return CreateRequest("[Dashboard].[GetUserCreditHistory]", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetUserOperations = (params) => {
  return CreateRequest("[Dashboard].[GetUserOperations]", {
    ...params,
  });
};

export const GetUserInfo = (params) => {
  return CreateRequest("[Dashboard].[GetUserInfo]", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetAccountInfo = (params) => {
  return CreateRequest("[Dashboard].[GetAccountInfo]", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const UpdateUserInfo = (params, tablesNames) => {
  return CreateRequest("[Dashboard].[UpdateUserInfo]", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  },
    null, tablesNames
  );
};

export const AccountActivation = (params) => {
  return CreateRequest("[Dashboard].ToggleClientActivity", {
    ...params,
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetUserDetailsReport = (params) => {
  return CreateRequest(
    "[Dashboard].GetUserDetailsReport",
    { ...params, "@adminID": localStorage.getItem("userId") },
    Excel_API
  );
};
export const GetDetailsReportAdmin = (params) => {
  return CreateRequest(
    "[Dashboard].[GetDetailsReportAdmin]",
    { ...params, "@adminID": localStorage.getItem("userId") },
    Excel_API
  );
};

export const GetClients = () => {
  return CreateRequest("[Dashboard].GetClients_Lookup", {
    "@adminID": localStorage.getItem("userId"),
  });
};

export const GetClientsSenders = (params) => {
  return CreateRequest("[Dashboard].GetClientsSenders_Lookup", {
    ...params,
  });
};


export const GetAllCampaigns = (params) => {
  return CreateRequest("[Dashboard].GetAllCampaigns", {
    ...params, "@adminID": localStorage.getItem("userId")
  });
};

export const GetCampaignsDetails = (params) => {
  return CreateRequest("[Dashboard].GetCampaignDetails", {
    ...params, "@adminID": localStorage.getItem("userId")
  });
};

export const GetDLR = (params) => {
  return CreateRequest("Dashboard.GetDLR", {
    ...params, "@adminID": localStorage.getItem("userId")
  });
};

export const GetOperatorsStatistics = (params) => {
  return CreateRequest("Dashboard.GetOperatorsStatistics", {
    ...params, "@adminID": localStorage.getItem("userId")
  });
}


export const GetDLRStatistics = (params) => {
  return CreateRequest("Dashboard.GetDLRStatistics", {
    ...params, "@adminID": localStorage.getItem("userId")
  });
};



export const ResendSms = (params) => {
  return CreateRequest("Dashboard.ResendSms", {
    ...params, "@adminID": localStorage.getItem("userId")
  });
};


