import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import "./style.css";

export default function ChangeLanguageBtns() {
  const { profileLanguage, setProfileLanguage } = useContext(Context);
  const { i18n } = useTranslation();
  const [logoSrc, setLogoSrc] = useState('images/logo.png');

  const changeLanguage = (language) => {
    setProfileLanguage(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (localStorage.getItem("i18nextLng") == "ar") {
      setProfileLanguage("ar");
      i18n.changeLanguage("ar");
    } else {
      setProfileLanguage("en");
      i18n.changeLanguage("en");
    }
  }, []);
  useEffect(() => {
    if (window.location.href.includes("portal.sms")) {
      setLogoSrc('images/epushLogo.png');
    } else {
      setLogoSrc('images/logo.png');
    }
  }, []);

  return (
    <div className="langButtonsMainContainer">
      <img src={logoSrc} alt="Logo" />
      <div className="language-buttons">
        <button
          className={`langButton ${localStorage.getItem("i18nextLng") === "en" ? "active" : ""
            }`}
          onClick={() => changeLanguage("en")}
        >
          English
        </button>
        <button
          className={`langButton ${localStorage.getItem("i18nextLng") === "ar" ? "active" : ""
            }`}
          onClick={() => changeLanguage("ar")}
        >
          العربية
        </button>
      </div>
    </div>
  );
}
