import React, { useState, useContext } from "react";
import { Context } from "../../context";
import { Link } from "react-router-dom";
import { Login, GenerateOTP } from "../../API";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ChangeLanguageBtns from "../../component/changeLanguageBtns";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "./style.css";

export default function LogIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { setIsLoggedIn } = useContext(Context);
  const { role, setRole } = useContext(Context);
  const [verfied, setVerifed] = useState(false);

  function onChange(value) {
    setVerifed(true);
    axios
      .post(
        "https://hub.advansystelecom.com/generalapiv12/api/google/Recaptcha",
        {
          AppName: "AdvansysBulk",
          Token: value,
        }
      )
      .then((response) => {
        if (response.data.success === "true" && response.status === 200)
          setVerifed(true);
      })
      .catch((err) => {
        toast.error(t("errorMessage"));
      });
  }

  const login = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setVerifed(true);
    if (username.trim() === "" || password.trim() === "") {
      toast.error(t("enterUsernameAndPassword"));
      setIsLoading(false);
      return;
    }
    Login({ username, password })
      .then((response) => {
        setIsLoading(false);
        if (!response.data.token) {
          toast.error(t("logInError"));
          return;
        }
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.data.Table[0].userID);
        localStorage.setItem("userName", response.data.data.Table[0].username);
        localStorage.setItem("admin", response.data.data.Table[0].admin);
        setIsLoggedIn(true);
        setRole(localStorage.getItem("admin"));
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <div className="loginMainContainer" style={{ backgroundImage: 'url("images/loginBG.jpeg")' }}>
      <div className="loginHeader">
        <ChangeLanguageBtns />
      </div>
      <div className={`loginFrameContainer ${i18n.language} left-side`}>
        <div className="formLoginSignUp">
          <div className={`mainTitle ${i18n.language}`}>{t("signIn")}</div>
          <div className={`subTitle ${i18n.language}`}>
            {t("loginSubTitle")}
          </div>
          <form id="submitForm" onSubmit={login}>
            <div className={`form-group ${i18n.language}`}>
              <Field
                inputFieldValue={username}
                setOnChange={setUsername}
                placeholder={t("userName")}
                labelName={t("userName")}
              />
            </div>

            <div className={`form-group ${i18n.language}`}>
              <Field
                inputFieldType={"password"}
                inputFieldValue={password}
                setOnChange={setPassword}
                placeholder={t("password")}
                labelName={t("password")}
              />
              <div className="forgetPassword">
                <Link
                  className="loginSignUpFormLink"
                  to="/requestChangePassword"
                >
                  {t("forgotPassword")}
                </Link>
              </div>
              <div className="captchaDiv">
                <ReCAPTCHA
                  sitekey="6Ld3hnIjAAAAACKBfcmOG5nbjae8SKkuF_NbEaa3"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className={`form-group ${i18n.language} pt-1`}>
              <button
                type="submit"
                id="loginBtn"
                disabled={!verfied || isLoading}
              >
                {isLoading && (
                  <span className="spinner-border" role="status"></span>
                )}
                {t("signIn")}
              </button>
            </div>
          </form>
          <div className={`secondTitle ${i18n.language}`}>
            {t("donotHaveAccount")}{" "}
            <Link className="loginSignUpFormLink" to="/SignUp">
              {t("signUp")}
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="split-screen">
        <div className="right-side">
          <div className="loginLogoBG">
            <img id="mainLogoMainPage" src="images/logo.png" alt="main logo" />
          </div>
          <img id="mainLoginLogo" src="images/loginBG.png" alt="main logo" />
        </div>
      </div> */}
    </div>
  );
}
