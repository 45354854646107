import React, { useState, useRef } from "react";
import { SignUp } from "../../API";
import { Link, useNavigate } from "react-router-dom";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import ChangeLanguageBtns from "../../component/changeLanguageBtns";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./style.css";

export default function SignUpPage() {
  const [email, setEmail] = useState("");
  const location = useLocation();
  const hasEPush = window.location.href.includes("portal.sms");
  const parentID = hasEPush ? "402" : null;
  const ID = location.search.split("?packageID=")[1];
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [tax, setTax] = useState("");
  const [showErrorMsg, setShowErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const mobileNumberRegex = /^01[0125][0-9]{8}$/;
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

  const signUpBtn = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowErrorMsg(false);

    if (!emailRegex.test(email)) {
      toast.error(t("e-mailInvalid"));
      setIsLoading(false);
      return;
    }
    if (!mobileNumberRegex.test(phone)) {
      toast.error(t("phoneNumberInvalid"));
      setIsLoading(false);
      return;
    }
    if (tax.trim() === "") {
      toast.error("Enter your tax number");
      setIsLoading(false);
      return;
    }
    if (!passwordRegex.test(password)) {
      toast.error(t("passwordInvalid"));
      setIsLoading(false);
      return;
    }
    SignUp({
      "@email": email,
      "@username": username,
      "@password": password,
      "@phoneNumber": phone,
      "@taxNumber": tax,
      "@pakageID": ID,
      "@parentID": parentID
    })
      .then((response) => {
        if (response.data.data.Table[0].Column1 == 1) {
          toast.error(t("usernameExists"));
          setIsLoading(false);
          return;
        }
        if (response.data.data.Table[0].Column1 == 2) {
          toast.error(t("emailExists"));
          setIsLoading(false);
          return;
        }
        axios
          .post(
            "https://hub.advansystelecom.com/generalapiv11/api/bulkSMS/SendEmail",
            { Email: email, isSMSHub: window.location.href.includes("portal.sms") ? 1 : 0 }
          )
          .then((res) => {
            if (res.status === 200) {
              setIsLoading(false);
              navigate("/emailVerification");
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });

        // if (!response.data.token) {
        //   setShowErrorMsg(true);
        //   setIsLoading(false);
        //   return;
        // }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  return (
    <div className="signUpMainContainer" style={{ backgroundImage: 'url("images/loginBG.jpeg")' }}>
      <div className="loginHeader">
        <ChangeLanguageBtns />
      </div>
      <div className={`loginFrameContainer ${i18n.language} left-side`}>
        <div className="formLoginSignUp">
          <div className={`mainTitle ${i18n.language}`}>{t("signUp")}</div>
          <div className={`subTitle ${i18n.language}`}>
            {t("signUpSubTitle")}
          </div>
          <form id="submitForm" onSubmit={signUpBtn}>
            <div className={`form-group ${i18n.language}`}>
              <Field
                inputFieldValue={email}
                setOnChange={setEmail}
                placeholder={t("email")}
                labelName={t("email")}
              />
            </div>
            <div className={`form-group ${i18n.language}`}>
              <Field
                inputFieldValue={username}
                setOnChange={setUsername}
                placeholder={t("userName")}
                labelName={t("userName")}
              />
            </div>
            <div className={`form-group ${i18n.language}`}>
              <Field
                inputFieldValue={phone}
                setOnChange={setPhone}
                placeholder={t("phonenumber")}
                labelName={t("phonenumber")}
                inputFieldType="number"
              />
            </div>
            <div className={`form-group ${i18n.language}`}>
              <Field
                inputFieldValue={tax}
                setOnChange={setTax}
                placeholder={t("taxnumber")}
                labelName={t("taxnumber")}
              />
            </div>
            <div className={`form-group ${i18n.language}`}>
              <Field
                inputFieldType={"password"}
                inputFieldValue={password}
                setOnChange={setPassword}
                placeholder={t("password")}
                labelName={t("password")}
              />
            </div>
            {showErrorMsg && <div id="erorrMsg">Invalid input data</div>}
            <div className={`form-group ${i18n.language} pt-1 mt-3`}>
              <button
                id="signUpBtn"
                type="submit"
                onClick={() => signUpBtn()}
                disabled={isLoading}
              >
                {isLoading && (
                  <span className="spinner-border" role="status"></span>
                )}
                {t("signUp")}
              </button>
            </div>
          </form>
          <div className={`secondTitle ${i18n.language}`}>
            {t("haveAnAccount")}{" "}
            <Link className="loginSignUpFormLink" to="/SignIn">
              {t("signIn")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
