import React, { useState, useMemo, useCallback, useContext } from "react";
import { GetAllUserPackages, GetAllUserPackagesExport } from "../../API";
import { Context } from "../../context";
import { toast } from "react-toastify";
import TableDatePicker from "../../component/datePicker";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import "./style.css";

export default function PackagesHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [data, setData] = useState([]);
  const [toggleSideBar] = useState(false);
  const { t } = useTranslation();

  const exportHandler = () => {
    setIsLoadingBtn(true);
    GetAllUserPackagesExport({
    })
      .then((response) => {
        setIsLoadingBtn(false);
        let link = document.createElement("a");

        link.download = "PackagesReport";
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          response.data;

        if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent))
          link.download += ".xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("Balance"),
        accessor: "Balance",
      },
      {
        Header: t("amount") + " (EGP)",
        accessor: "Cost",
        Cell: ({ row: { original } }) => {
          return (
            <CurrencyFormat
              value={original.Cost}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        },
      },
      {
        Header: t("smsPrice") + " (EGP)",
        accessor: "smsPrice",
        Cell: ({ row: { original } }) => {
          return (
            <CurrencyFormat
              value={(original.Cost / original.Balance).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        },
      },
      {
        Header: t("Date"),
        accessor: "CreatedDate",
      },
    ],
    [t]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetAllUserPackages({
        "@page": pageIndex,
        "@rowsPerPage": pageSize
      })
        .then((response) => {
          setIsLoading(false);

          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [t]
  );

  return (
    <div className="transactionContainer">
      <Header
        title={t("PackagesHistoryTitle")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div id="packagesHistoryExport">
        <button
          className="exportBtn"
          onClick={() => exportHandler()}
          disabled={isLoadingBtn}
        >
          {t("exportReport")}
          {isLoadingBtn && (
            <span className="spinner-border" role="status"></span>
          )}
        </button>


      </div>
      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("PackagesHistoryTitle")}
        />
      </div>
    </div>
  );
}
