import React, { useState, useMemo, useCallback, useContext } from "react";
import Table from "../../component/newTable";
import { toast } from "react-toastify";
import { GetBusinessSector } from "../../API";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import Header from "../../component/header";
import AddBusinessSectorPopUp from "../../component/addBusinessSectorPopUp";
import DeleteBusinessSectorPopUp from "../../component/deleteBusinessSectorPopUp";
import "./style.css";

export default function BusinessSector() {
  const [data, setData] = useState([]);
  const { role, setAddBusinessSectorPopUp, setDeleteBusinessSectorPopUp, reload } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSideBar] = useState(false);
  const [businessSectorID, setBusinessSectorID] = useState(null);
  const [businessSectorName, setBusinessSectorName] = useState(null);
  const { t } = useTranslation();
  const showEdit = (id) => {
    setBusinessSectorID(id);
    setAddBusinessSectorPopUp(true);
  };
  const showDelete = (id, name) => {
    setBusinessSectorID(id);
    setBusinessSectorName(name);
    setDeleteBusinessSectorPopUp(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("name"),
        accessor: "BusinessSectorName",
      },
      {
        Header: " ",
        accessor: "",
        Cell: ({ row: { original } }) => {
          return (
            <div className="tableActionsCol">
              <i
                className="fas fa-pen "
                onClick={() => showEdit(original.ID)}
              ></i>
              <i className="fas fa-trash" onClick={() => showDelete(original.ID, original.BusinessSectorName)}></i>
            </div>
          );
        },
      },
    ],
    [t, role]
  );
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetBusinessSector({
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(`${err}`);
        });
    },
    [role, reload]
  );

  const handleShow = () => {
    setAddBusinessSectorPopUp(true);
    setBusinessSectorID(null);
  };


  return (
    <div className="sendersContainer">
      <Header
        title={t("BusinessSector")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <div className="senderTitleBtns">
          <button className="addBusinessSectorBtn" onClick={handleShow}>
            + {t("addBusinessSector")}
          </button>
        </div>
      </div>
      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={10}
          tableHeader={t("BusinessSector")}
          noPagination={1}
        />
        <AddBusinessSectorPopUp id={businessSectorID} />
        <DeleteBusinessSectorPopUp id={businessSectorID} name={businessSectorName} />
      </div>
    </div>
  );
}
