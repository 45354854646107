import React, { useState, useContext } from "react";
import { EditScheduleSMS } from "../../API";
import PopUp from "../../component/popUp";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import DatePicker from "react-datepicker";
import "./style.css";


export default function EditDateSchedule({
  openPopUp,
  closePopUp,
  id,
}) {
  const { t, i18n } = useTranslation();
  const { reload, setReload } = useContext(Context);
  const [sendDate, setSendDate] = useState(new Date());
  const deleteCampaign = (id) => {
    EditScheduleSMS({
      "@id": id,
      "@sendDate": sendDate,
    })
      .then((response) => {
        console.log(response);
        setReload(!reload);
        closePopUp();
        toast.success(`${t("sendDateUpdatedsuccessfully")}`);
      })
      .catch((error) => {
        toast.error("can't edit schedule date");
        console.log(error);
      });
  };


  const popUpTitle = (
    <div className="editDateStyle">
      Edit Campaign
    </div>
  );

  const popUpFooter = (
    <div className="mb-4">
      <button
        className="footerConfirmPopUpBtnsCancel"
        onClick={closePopUp}
      >
        {t("closeBtn")}
      </button>
      <button
        className="footerConfirmPopUpBtnsOk"
        onClick={() => deleteCampaign(id)}
      >
        {t("ok")}
      </button>
    </div>
  );

  return (
    <>
      <PopUp
        popUpTitle={popUpTitle}
        popUpFooter={popUpFooter}
        showPopUp={openPopUp}
        closePopUp={closePopUp}
      >
        <div className="editDateLabel">
          {t("scaduleMessage")}
        </div>
        <DatePicker
          selected={sendDate}
          startDate={sendDate}
          onChange={(date) => setSendDate(date)}
          className="editSendDateDatePicker"
          minDate={new Date()}
          minTime={new Date()}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
        />
        <div>
        </div>

      </PopUp>
    </>
  );
}
