import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import axios from "axios";
import "./style.css";

export default function RequestChangePassword() {

    const { resetPasswordEmail, setResetPasswordEmail } = useContext(Context);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const checkEmail = () => {

        if (!emailRegex.test(resetPasswordEmail)) {
            toast.error(t("e-mailInvalid"));
            return;
        }
        setIsLoading(true);

        axios
            .post(
                "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/SendEmail",
                { Email: resetPasswordEmail, isSMSHub: window.location.href.includes("portal.sms") ? 1 : 0 }
            )
            .then((res) => {
                if (res.status === 200) {
                    setIsLoading(false);
                    navigate("/code");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error("We couldn't find an account associated with that email address. Please double-check your email and try again.");
            });

    }

    return (
        <>
            <div className="row">
                <div className={`col-md-4 col-sm-12 leftCol ${i18n.language}`}>
                    <div>
                        <img id="companyLogo" src={window.location.href.includes("portal.sms") ? "images/epushLogo.png" : "images/logo.png"} alt="main logo" />
                    </div>
                    <div className="forgotPasswordTitle">
                        {t("forgotPassword")}
                    </div>
                    <div className="forgotPasswordDesc">
                        {t("donotWorry")}<br />{t("stillRemember")}<br />{t("canQuickly")}
                    </div>
                    <div className="form-group styelFiled">
                        <Field
                            inputFieldValue={resetPasswordEmail}
                            setOnChange={setResetPasswordEmail}
                            placeholder={t("email")}
                            labelName={t("email")}

                        />
                    </div>
                    <div className="form-group pt-1 mt-3">
                        <button id="requestChangeBtn" disabled={isLoading} onClick={() => checkEmail()} type="submit">
                            {t("requestPasswordChange")}
                            {isLoading && (
                                <span className="spinner-border" role="status"></span>
                            )}
                        </button>
                    </div>
                    <div className="noteStyle"><span className="noteLink" onClick={() => navigate("/login")}> {t("backToLogin")}</span></div>
                </div>
                <div class="col-md-8 col-sm-12 rightCol">
                    <img id="changePasswordImg" src="images/changePassword.png" alt="main logo" />
                </div>
            </div>

        </>
    );
}
