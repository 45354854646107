import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { GetCampaignsDetails } from "../../API";
import { toast } from "react-toastify";
import PopUp from "../popUp";
import Table from "../../component/newTable";
import { useNavigate } from 'react-router-dom';
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import "./style.css";
export default function CampaignDetailsPopUp({
  campaignID
}) {

  const { campaignDetailsPopUp, setCampaignDetailsPopUp } = useContext(Context);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState();
  const navigate = useNavigate();

  const linkDLRTable = (id) => {
    setCampaignDetailsPopUp(false);
    navigate(`/DLRTable/${id}`);
  }
  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("sender"),
        accessor: "Sender",
      },
      {
        Header: t("date"),
        accessor: "SendDate",
      },
      {
        Header: t("CampaignName"),
        accessor: "CampaignName",
        Cell: ({ row: { original } }) => {
          return <div className="viewDetailsStyle" onClick={() => linkDLRTable(original.ID)}>{original.CampaignName}</div>;
        },
      },
      {
        Header: t("CampaignType"),
        accessor: "CampaignType",
        Cell: ({ row: { original } }) => {
          return <div>{original.CampaignType == 1 ? <div className="campaignTypeStyle">{t("oneToOneCampaign")}</div> : original.CampaignType == 2 && <div className="campaignTypeStyle">{t("bulkCampaign")}</div>}</div>
        },
      }
    ],
    [t]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (!campaignID)
        return;
      setIsLoading(true);
      setData([]);
      GetCampaignsDetails({
        "@campaignID": campaignID
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
          if (response.data.table1[0].CampaignType == 2)
            setMsg(response.data.table1[0].data);
          else if (response.data.table1[0].CampaignType == 1)
            setMsg(t("dynamicMsg"))
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [t, campaignID]
  );

  const campaignDetailsPopUpTitle =
    <div style={{ textAlign: "center" }}>
      <div>{t("CampaignDetails")}</div>
    </div>;
  const campaignDetailsPopUpFooter = (
    <>

      <button
        className="closeBtn"
        onClick={() => setCampaignDetailsPopUp(false)}
      >
        {t("closeBtn")}
      </button>
    </>
  );

  return (
    <>
      <PopUp
        popUpTitle={campaignDetailsPopUpTitle}
        children={
          <>
            <div className={`form-group  ${i18n.language}`}>
              <div className="tableDiv">
                <Table
                  columns={columns}
                  data={data}
                  fetchData={fetchData}
                  loading={isLoading}
                  TotalRows={10}
                  noPagination={true}
                />
              </div>
              {(!isLoading && msg != t("dynamicMsg")) && (<div className="campaignMsg"><span className="campaignMsgTitle">{t("Content")}</span><span className="campaignMsgContent">{msg}</span></div>)}
            </div>
          </>
        }
        popUpFooter={campaignDetailsPopUpFooter}
        showPopUp={campaignDetailsPopUp}
        closePopUp={() => setCampaignDetailsPopUp(false)}
      />
    </>
  );
}
