import React from "react";
import { Modal } from "react-bootstrap";
import "./style.css";

export default function PopUp({
  popUpTitle,
  popUpFooter,
  showPopUp,
  closePopUp,
  children,
}) {
  return (
    <Modal className="modalBody" show={showPopUp} onHide={closePopUp}>
      <Modal.Header>
        <Modal.Title>{popUpTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>{popUpFooter}</Modal.Footer>
    </Modal>
  );
}
